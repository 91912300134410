import { Link, useLocation } from 'react-router-dom'
import { checkIsActive, toAbsoluteUrl } from '../../../helpers'

import { MediaImage } from '../../../framework/design/components'
import clsx from 'clsx'
import { useAuth } from '../../../modules/auth'

export function MenuInner() {
  const { siteId } = useAuth()
  return (
    <HomeMenuItem to={`/site(${siteId})/dashboard`} />
  )
}

const HomeMenuItem = ({ to }: { to: string }) => {
  const { pathname } = useLocation()
  const { site } = useAuth()

  return (
    <div className='menu-item me-lg-1'>
      <Link
        className={clsx('menu-link py-3 bg-transparent', {
          active: checkIsActive(pathname, to),
        })}
        to={to}>
        <div className='d-flex align-items-center'>
          {(site && site.logo && site.logo !== '00000000-0000-0000-0000-000000000000') ?
            <MediaImage className='h-50px me-5' mediaId={site?.logo} /> :
            <>
              {(site?.title) && <>
                {/* <AvatarText label={site?.title} size={2} className='me-3 fw-bolder' random={true} /> */}
                <img
                    alt='Logo'
                    className='h-50px logo me-3'
                    src={toAbsoluteUrl('/media/logos/EdtechLogo.png')}
                  />
              </>}
            </>}
          <h1 className='my-1 fw-bolder'>{site?.title || 'Portal Framework'}</h1>
        </div>
      </Link>
    </div>)
}