import { createContext, useContext, useState } from "react"

import Toast from "react-bootstrap/esm/Toast";
import ToastContainer from "react-bootstrap/esm/ToastContainer";

interface IToastModel {
    showToaster: (message: string | IToastMessage) => void
}

interface IToastMessage {
    show?: boolean,
    header?: string,
    delay?: number,
    autoHide?: boolean,
    message?: string
}

const context = createContext<IToastModel>({
    showToaster: () => { }
})

export const ToasterProvider = ({ children }: { children?: React.ReactNode }) => {
    const [toast, setToast] = useState<IToastMessage>({ show: false })

    const showToaster = (message: string | IToastMessage) => {
        if (message instanceof String) {
            setToast({ show: true, message: message as string })
        } else {
            setToast({ ...(message as IToastMessage), show: true })
        }
    }

    return (
        <context.Provider value={{
            showToaster,
        }}>
            <ToastContainer className="p-3" position={"bottom-end"}>
                <Toast onClose={() => setToast({ show: false })}
                    show={toast.show}
                    delay={toast.delay || 2000}
                    autohide={toast.autoHide}>
                    {toast.header && <>
                        <Toast.Header>
                            <h4 className="flex-fill fs-6">{toast.header}</h4>
                        </Toast.Header>
                    </>}
                    <Toast.Body>
                        <div className="fs-6" dangerouslySetInnerHTML={{ __html: toast.message || '' }} />
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {children}
        </context.Provider>
    )
}

export function useToaster() {
    return useContext(context)
}
