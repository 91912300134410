import clsx from "clsx"
import { toAbsoluteUrl } from "../../../../helpers"

export const MsalSignInButton = ({ loading, className, onClick }: {
    loading?: boolean,
    className?: string,
    onClick: () => void
}) => {
    return <>
        <button
            type='button'
            disabled={loading}
            className={clsx(className, 'btn btn-primary btn-sm w-100 d-flex flex-row justify-content-center align-items-center')}
            onClick={() => onClick()}
        >
            {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
            )}
            {!loading && <>
                <div className="">
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/svg/brand-logos/microsoft-5.svg')}
                        className='h-15px me-1'
                    />
                    <span className="m-0 fs-8">Sign in with your JGU Email ID</span>
                </div>
            </>
            }
        </button>
    </>
}