import * as Yup from 'yup'

import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { Spinner } from '../../../framework/widgets'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { useFormik } from 'formik'
import { useRequestPasswordMutation } from '../../../framework/services/auth'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [requestPassword] = useRequestPasswordMutation()
  const [linkSent, setLinkSent] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus('')
      setLoading(true)
      requestPassword(values.email)
        .unwrap()
        .then(() => setLinkSent(true))
        .catch((err) => {
          setStatus(err.data || 'The login details are incorrect')
        })
        .finally(() => {
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <div className='auth-card-responsive card bg-transparent'>
      <div className='logo'>
        <img alt='Logo' src={toAbsoluteUrl('/media/background-images/logo.png')} className='w-100' />
      </div>
      <div className='card-body fs-8'>
        <form
          className='form mx-auto fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center'>
            <h6 className='text-white mb-3'>Forgot Password ?</h6>
            <div className='text-gray-400 fw-semibold fs-7'>
              Enter your email to reset your password.
            </div>
          </div>

          {formik.status && (
            <div className='alert alert-danger px-5 py-3'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {linkSent && (
            <div className='bg-light-info px-5 py-3 rounded'>
              <div className='text-info'>Password reset link sent. Please check your email</div>
            </div>
          )}

          {!linkSent && <>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bold text-white fs-8'>Email</label>
              <input
                type='email'
                placeholder='Email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-sm',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  { 'is-valid': formik.touched.email && !formik.errors.email, }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-wrap justify-content-center gap-3'>
              <button
                type='submit'
                disabled={loading}
                id='kt_password_reset_submit'
                className='btn btn-sm btn-primary fw-bolder py-2'
              >
                {loading && <Spinner className='me-2' />}
                <span className='indicator-label'>Submit</span>
              </button>

              <Link to='/auth/login' className='btn btn-sm btn-secondary fw-bolder py-2'>
                Cancel
              </Link>
            </div>
          </>}
        </form>
      </div>
    </div>
  )
}
