import { AlertMessage } from "../../../../framework/widgets";
import { InteractionStatus } from "@azure/msal-browser";
import { LoginRequest } from "../authConfig";
import { MsalSignInButton } from "./MsalSignInButton";
import { MsalStatus } from "../msal-status";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";

export const UnauthenticatedSignIn = ({ className }: { className?: string }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { instance, inProgress } = useMsal();
    const [status, setStatus] = useState<string>('')

    const handleLogin = async () => {
        setLoading(true)
        
        MsalStatus.start()
        instance.loginRedirect(LoginRequest)
            .catch(() => {
                MsalStatus.clear()
                setStatus('Login failed')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return <>
        <MsalSignInButton
            loading={loading || inProgress !== InteractionStatus.None}
            className={className}
            onClick={handleLogin} />
        <AlertMessage show={!!status} message={status} />
    </>
}
