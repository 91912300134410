/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup'

import { SignInButton, useAuthProviders } from '../msal'

import { Link } from 'react-router-dom'
import { MsalStatus } from '../msal/msal-status'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { useAuth } from '../core/Auth'
import { useFormik } from 'formik'
import { useLoginMutation } from '../../../framework/services/auth'
import { useState } from 'react'

const loginSchema = Yup.object().shape({
  userName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('User name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  userName: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setUser } = useAuth()
  const [login] = useLoginMutation()
  const { authTypes } = useAuthProviders()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSubmitting(true)

      login({ userName: values.userName, password: values.password })
        .unwrap()
        .then((result) => {
          saveAuth(result.auth)
          setUser(result.user)
        })
        .catch(err => {
          saveAuth(undefined)
          setStatus(err.data?.error?.message || 'The login details are incorrect')
        })
        .finally(() => {
          setLoading(false);
          setSubmitting(false)
        })
    },
  })

  if (MsalStatus.isInProgress()) {
    return <div className='d-flex flex-column align-items-center justify-content-center h-100 w-100'>
      <SignInButton className='btn-sm w-250px' />
    </div>
  }

  return (
    <div className='auth-card-responsive card bg-transparent'>
      <div className='logo'>
        <img alt='Logo' src={toAbsoluteUrl('/media/background-images/logo.png')} className='w-100' />
      </div>
      <div className='card-header border-0 min-h-5px'>        
        <h6 className='text-white my-0 fs-6'>Sign in as Parent</h6>
      </div>
      <div className='card-body pt-0 py-2'>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {formik.status && (
            <div className='alert alert-danger px-5 py-3'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          <div className='row'>
            <div className='col-12 mb-2'>
              <label className='form-label fw-bolder text-light fs-8 my-0'>User Name</label>
              <input
                placeholder='User Name'
                {...formik.getFieldProps('userName')}
                className={clsx(
                  'form-control form-control-sm py-1',
                  { 'is-invalid': formik.touched.userName && formik.errors.userName },
                  { 'is-valid': formik.touched.userName && !formik.errors.userName, }
                )}
                type='userName'
                name='userName'
                autoComplete='off'
              />
              <FieldError
                show={formik.touched.userName && !!formik.errors.userName}
                message={formik.errors.userName} />
            </div>

            <div className='col-12 mb-2'>
              <div className='d-flex flex-stack align-items-center'>
                <label className='form-label fw-bolder text-light fs-8 my-0'>Password</label>
                <Link
                  to='/auth/forgot-password'
                  className='link-primary fw-bold fs-8 my-0'
                >
                  Forgot Password ?
                </Link>
              </div>
              <input
                type='password'
                placeholder='Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-sm py-1',
                  { 'is-invalid': formik.touched.password && formik.errors.password, },
                  { 'is-valid': formik.touched.password && !formik.errors.password, }
                )}
              />
              <FieldError
                show={formik.touched.password && !!formik.errors.password}
                message={formik.errors.password} />
            </div>

            <div className='text-center mt-2'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-sm btn-primary w-100 fs-8'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Sign in</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
        {(authTypes.indexOf("MSAL") !== -1) && <>
          <>
            <div className='text-center mt-1'>
              <h6 className='text-white my-2 fs-6'>Sign in as Student</h6>
            </div>
            <div className='p-0 m-0'>
              <SignInButton className='btn-sm fs-8' />
            </div>
          </>
        </>}
      </div>
    </div>
  )
}

const FieldError = ({ show, message }: { show?: boolean, message?: string }) => {
  if (!show) return <></>

  return <div className='fv-plugins-message-container'>
    <div className='fv-help-block fs-8'>
      <span role='alert'>{message}</span>
    </div>
  </div>
}