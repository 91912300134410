import * as Yup from 'yup'

import React, { useState } from 'react'
import { useLazyGetUserByTokenQuery, useResetPasswordMutation } from '../../../framework/services/auth'

import { Link } from 'react-router-dom'
import { Spinner } from '../../../framework/widgets'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { useAuth } from '../core/Auth'
import { useFormik } from 'formik'
import { useSearchParams } from "react-router-dom"

const initialValues = {
    password: '',
    confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        }),
})

export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const [resetPassword] = useResetPasswordMutation()
    const [getUserByToken] = useLazyGetUserByTokenQuery()
    const { saveAuth, setUser } = useAuth()
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (!email || !token) return;

            const { password } = values

            setStatus('')
            setLoading(true)
            resetPassword({ email, password, token })
                .unwrap()
                .then((auth) => {
                    saveAuth({ ...auth, authType: "Custom" })
                    return getUserByToken(auth.token)
                        .unwrap()
                        .then(user => {
                            setUser(user)
                        })
                })
                .catch((err) => {
                    setStatus(err.data.message || 'Could not reset the password.')
                })
                .finally(() => {
                    setLoading(false)
                    setSubmitting(false)
                })
        },
    })

    return (
        <div className='auth-card-responsive card bg-transparent'>
            <div className='logo'>
                <img alt='Logo' src={toAbsoluteUrl('/media/background-images/logo.png')} className='w-100' />
            </div>
            <div className='card-body fs-8'>
                <form
                    className='form mx-auto fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='text-center mb-3'>
                        <h6 className='text-white mb-2'>Reset Password</h6>
                        <div className='text-gray-400 fw-bold fs-7'>Enter your new password.</div>
                    </div>

                    {formik.status && (
                        <div className='alert alert-danger px-5 py-3'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    )}


                    <div className='mb-5 d-flex flex-row flex-wrap gap-3'>
                        <div className='flex-fill'>
                            <label className='form-label fw-bold text-white fs-8'>New Password</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control form-control-sm',
                                        { 'is-invalid': formik.touched.password && formik.errors.password, },
                                        { 'is-valid': formik.touched.password && !formik.errors.password, }
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='flex-fill'>
                            <label className='form-label fw-bold text-white fs-8'>Confirm Password</label>
                            <input
                                type='password'
                                placeholder='Password confirmation'
                                autoComplete='off'
                                {...formik.getFieldProps('confirmPassword')}
                                className={clsx(
                                    'form-control form-control-sm',
                                    { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword, },
                                    { 'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword, }
                                )}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.confirmPassword}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='d-flex flex-wrap justify-content-center gap-3'>
                        <button
                            type='submit'
                            disabled={loading || (!email || !token)}
                            id='kt_password_reset_submit'
                            className='btn btn-sm btn-primary fw-bolder py-2'
                        >
                            {loading && <Spinner className='me-2' />}
                            <span className='indicator-label'>Submit</span>
                        </button>

                        <Link to='/auth/login' className='btn btn-sm btn-secondary fw-bolder py-2'>
                            Cancel
                        </Link>{' '}
                    </div>
                </form>
            </div>
        </div>
    )
}
